<template>
  <div id="simple-text-main">
    <div id="simple-text" v-html="container"></div>
  </div>
</template>
<script>
import { datatexten } from "@/data/datatext.js";
import { datatextfr } from "@/data/datatextfr.js";
import { datatextab } from "@/data/datatextab.js";
export default {
  data() {
    return { container: "" };
  },
  created() {
    if (this.$route.name == "EnVersion") {
      this.container = datatexten.simpleText;
    }
    if (this.$route.name == "FrVersion") {
      this.container = datatextfr.simpleText;
    }
    if (this.$route.name == "AbVersion") {
      this.container = datatextab.simpleText;
    }
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
#simple-text-main {
  padding-top: 30px;
  padding-bottom: 30px;
  max-width: 1200px;
  margin: 0 auto;
  #simple-text {
    max-width: 760px;
    margin: 0 auto;
    font-size: 20px;
    line-height: 1.55;
    font-weight: 300;
  }
}
</style>
