<template>
  <div id="contact-block-main">
    <div id="contact-block-wrapper">
      <div id="contact-block-wrapper-two">
        <div id="contact-block">
          <a
            href="https://wa.me/79234894232"
            target="_blank"
            class="t-btn"
            style="color: #ffffff; background-color: #199c19"
            ><table style="width: 100%; height: 100%">
              <tbody>
                <tr>
                  <td>{{ container.btn1 }}</td>
                </tr>
              </tbody>
            </table></a
          >
          <a
            href="viber://chat?number=79234894232"
            target="_blank"
            class="t-btn t-btn-left-margin"
            style="
              color: #ffffff;
              border: 3px solid #bd60c9;
              background-color: #bd60c9;
            "
            ><table style="width: 100%; height: 100%">
              <tbody>
                <tr>
                  <td>{{ container.btn2 }}</td>
                </tr>
              </tbody>
            </table></a
          ><a
            @click="scrolltoelem('contacts-main')"
            target=""
            class="t-btn t-btn-left-margin"
            style="
              color: #ffffff;
              border: 3px solid #f08408;
              background-color: #f08408;
            "
            ><table style="width: 100%; height: 100%">
              <tbody>
                <tr>
                  <td>{{ container.btn3 }}</td>
                </tr>
              </tbody>
            </table></a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { datatexten } from "@/data/datatext.js";
import { datatextfr } from "@/data/datatextfr.js";
import { datatextab } from "@/data/datatextab.js";
export default {
  data() {
    return { container: {} };
  },
  created() {
    if (this.$route.name == "EnVersion") {
      this.container = datatexten.contactBlock;
    }
    if (this.$route.name == "FrVersion") {
      this.container = datatextfr.contactBlock;
    }
    if (this.$route.name == "AbVersion") {
      this.container = datatextab.contactBlock;
    }
  },
  methods: {
    scrolltoelem(elem) {
      document.getElementById(elem).scrollIntoView({ behavior: "smooth" });
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
#contact-block-main {
  padding-top: 15px;
  padding-bottom: 30px;
  opacity: 1;
  transition: opacity 0.5s;
  #contact-block-wrapper {
    width: 100%;
    padding: 0;
    #contact-block-wrapper-two {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      #contact-block {
        position: relative;
        z-index: 1;
        .t-btn {
          text-decoration: none;
          display: inline-block;
          font-family: "Roboto", Arial, sans-serif;
          height: 60px;
          border: 0 none;
          font-size: 16px;
          padding-left: 60px;
          padding-right: 60px;
          text-align: center;
          white-space: nowrap;
          vertical-align: middle;
          font-weight: 700;
          background-image: none;
          cursor: pointer;
          -webkit-appearance: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          -o-user-select: none;
          user-select: none;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
        }
        .t-btn-left-margin {
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
