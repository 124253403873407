<template>
  <div id="partners">
    <div id="title">Our Partners</div>
    <div class="text">
      Below are the names and contact details of contractors/agents (educational
      consultants) approved by Kuzbass State Agricultural University.
    </div>
    <div class="partners">
      <div class="partners__card">
        <img src="@/assets/medicos.jpg" />
        <div class="partners__card-content">
          <p class="partners__title">WhatsApp Number</p>
          <p class="partners__text">+91 9310237614</p>
          <p class="partners__text">+7 9605862323</p>
        </div>
        <div class="partners__card-content">
          <p class="partners__title">Abroad Meidicos Consultants</p>
          <p class="partners__text">
            456, Sectorc19., Dwarka, New Delhi, 119975
          </p>
        </div>
        <div class="partners__card-content">
          <p class="partners__title">We are on</p>
          <p class="partners__text">
            <a
              href="https://youtube.com/@abroadmedicosconsultants?si=NkfKtB0iq_vIqIpq"
              target="_blank"
              >YouTube Channel</a
            >
          </p>
          <p class="partners__text">
            <a
              href="https://www.instagram.com/abroadmedicosconsultants?igsh=Y2JvcWk4cGRqNG53&utm_source=qr"
              target="_blank"
              >Instagram
            </a>
          </p>
          <p class="partners__text">
            <a href="https://www.abroadmedicos.com" target="_blank">Website </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { datatexten } from "@/data/datatext.js";
import { datatextfr } from "@/data/datatextfr.js";
import { datatextab } from "@/data/datatextab.js";
export default {
  data() {
    return { container: {} };
  },
  mounted() {
    if (this.$route.name == "EnVersion") {
      this.container = datatexten.simpleTableText;
    }
    if (this.$route.name == "FrVersion") {
      this.container = datatextfr.simpleTableText;
    }
    if (this.$route.name == "AbVersion") {
      this.container = datatextab.simpleTableText;
    }
  },
};
</script>
<style lang="scss" scoped>
.partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  &__card {
    display: flex;
    flex-direction: row;
    gap: 100px;
    flex-wrap: wrap;
    justify-content: center;
    img {
      width: 150px;
    }
  }
  &__title {
    font-size: 18px;
    font-weight: bold;
  }
  &__text {
    font-size: 16px;
    a {
      text-decoration: none;
      color: grey;
    }
  }
  &__card-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
}
#partners {
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 0 auto;
  max-width: 1200px;
  margin-bottom: 60px;
  #title {
    margin-bottom: 52px;
    text-align: center;
    font-size: 42px;
    line-height: 1.23;
    font-weight: 700;
  }
}
.text {
  text-align: center;
  font-size: 18px;
  width: 100%;
  margin-bottom: 52px;
}
</style>
