<template>
  <div id="simple-text-video-main">
    <div id="simple-text-video-main-wrapper" :class="{ center: blockAb }">
      <strong> {{ container.title }} </strong>
      <div v-for="(slide, index) in container.li" :key="index">
        <span v-html="slide.text" v-if="blockAb == false"></span>
        <a v-bind:href="slide.url">{{ slide.url }}</a>
        <span
          v-html="slide.text"
          v-if="blockAb == true"
          :class="{ abblock: blockAb }"
        ></span>
      </div>
    </div>
  </div>
</template>
<script>
import { datatexten } from "@/data/datatext.js";
import { datatextfr } from "@/data/datatextfr.js";
import { datatextab } from "@/data/datatextab.js";
export default {
  data() {
    return { container: {}, blockAb: false };
  },
  mounted() {
    if (this.$route.name == "EnVersion") {
      this.container = datatexten.simpleTextVideos;
      this.blockAb = false;
    }
    if (this.$route.name == "FrVersion") {
      this.container = datatextfr.simpleTextVideos;
      this.blockAb = false;
    }
    if (this.$route.name == "AbVersion") {
      this.container = datatextab.simpleTextVideos;
      this.blockAb = true;
    }
  },
};
</script>
<style lang="scss" scoped>
.center {
  text-align: center !important;
}
.abblock {
  margin-left: 10px;
  font-weight: 600;
}
#simple-text-video-main {
  max-width: 1200px;
  width: 100%;
  padding-top: 45px;
  padding-bottom: 45px;
  display: table;
  margin: 0 auto;
  font-weight: 300;
  display: flex;
  justify-content: center;
  #simple-text-video-main-wrapper {
    max-width: 760px;
    display: inline;
    float: left;
    width: 100%;
    opacity: 0.7;
    font-size: 30px;
    line-height: 1.35;
    margin: 0 auto;
    margin-left: 20px;
    margin-right: 20px;
  }
  strong {
    font-weight: 700;
  }
  span {
    margin-right: 10px;
  }
  a {
    color: #ff8562;
    text-decoration: none;
  }
}
</style>
